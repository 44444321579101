<template>
  <b-overlay :show="!winSM && overlay" :rounded="$props.rounded ? 'lg' : ''" bg-color="#000" opacity="0.7" style="width: 100%; height: 100%">
    <template #overlay>
      <icon-play :clicked="play" :color="color" />
    </template>

    <iframe
      ref="video"
      width="100%"
      height="100%"
      :title="title"
      frameborder="0"
      allowfullscreen
      :src="srcAutoPlay"
      :style="$props.rounded ? 'border-radius:10px;' : ''"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'

import mixin from '@/mixins'
import IconPlay from './icons/IconPlay.vue'

export default {

  components: {
    IconPlay,
    BOverlay,
  },
  mixins: [mixin],

  props: {
    rounded: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: '#8D53BD',
    },

    title: {
      type: String,
      default: 'Youtube video player',
    },

    src: {
      type: String,
      default: 'https://www.youtube.com/embed/jU4Jl-pLuos',
    },
  },

  data() {
    return {
      overlay: true,
      srcAutoPlay: this.$props.src,
    }
  },

  methods: {
    play() {
      this.overlay = false
      this.srcAutoPlay += '?autoplay=1'
    },
  },
}
</script>
