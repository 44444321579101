<template>
  <svg :width="playSize" :height="playSize" viewBox="0 0 158 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="79" cy="79" r="75.5" stroke="url(#paint0_linear_880_3262)" stroke-width="7" />
    <circle cx="79" cy="76" r="44" :fill="color" style="cursor: pointer" @click="clicked" />
    <path d="M72.25 62.75L89.75 74L72.25 85.25V62.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_880_3262" x1="347.5" y1="464" x2="24" y2="40.5" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color" />
        <stop offset="0.935287" :stop-color="color" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import mixin from '@/mixins'

export default {
  mixins: [mixin],

  props: {
    size: {
      type: Number,
      default: 0,
    },

    color: {
      type: String,
      default: '#8D53BD',
    },

    clicked: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      playSize: 0,
    }
  },

  mounted() {
    if (this.$props.size > 0) this.playSize = this.$props.size
    else this.playSize = this.winSM ? 80 : 158
  },
}
</script>
